<template>
  <el-dialog title="车辆违章查询结果" width="700" :visible.sync="visible" :fullscreen="full" :close-on-click-modal="false" :close-on-press-escape="false" custom-class="v-dialog v-dialog--highLight risk-dialog" @open="computeHeight">
    <div>
      <tool :full-screen.sync="full" @download="download('车辆违章查询结果')"></tool>
      <h2 class="risk-dialogTitle">车辆违章查询结果</h2>
      <table class="risk-dialogTable">
        <colgroup>
          <col width="150"/>
          <col width="250"/>
        </colgroup>
        <tbody>
          <tr>
            <td>车牌</td>
            <td>{{v.result.result.lsprefix + v.result.result.lsnum}}</td>
          </tr>

        </tbody>
      </table>

      <table class="risk-dialogTable" v-if="v.result.result">
        <colgroup>
          <col width="150" />
          <col width="150" />
          <col width="150" />
          <col width="150" />
          <col width="150" />
        </colgroup>
        <thead>
          <tr>
            <th>时间</th>
            <th>地址</th>
            <th>内容</th>
            <th>罚款</th>
            <th>扣分</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in v.result.result.list" :key="index">
            <td>{{item.time}}</td>
            <td>{{item.address}}</td>
            <td>{{item.content}}</td>
            <td>{{item.price}}</td>
            <td>{{item.score}}</td>
          </tr>
        </tbody>
      </table>
    </div>

  </el-dialog>
</template>

<script>
import mixin from './mixin'
export default {
  data() {
    return {
      visible: false,
      v: null
    }
  },
  mixins: [mixin],
  methods: {
    open(datas) {
      this.visible = true
      this.v = Object.assign(this.v, datas)
    }
  },
  created() {
    // 初始化数据
    this.v = {}
  }
}
</script>
